export const environment = {
  //baseUrl: 'http://localhost:3010/api',
  baseUrl: 'https://api-dev.evoke.com.co/api',

  baseUrlLogin: 'user/login',
  baseUrlNewPassword: 'user/password-reset',
  baseUrlRecoverPassword: 'user/restore-password',

  baseUrlUserCreate: 'user/create',
  baseUrlUserDelete: 'user/delete',
  baseUrlUserList: 'user/list',
  baseUrlUserProfile: 'user/profile',
  baseUrlUserRead: 'user/read',
  baseUrlUserUdate: 'user/update',

  baseUrlListCity: 'city/list/client',
  baseUrlListClient: 'clients/list/whitoutpermissions',
  baseUrlListCountry: 'country/list/whitoutpermissions',
  baseUrlListDeliveryType: 'deliverytypes/list/whitoutpermissions',
  baseUrlListInstitution: 'institutions/list/client',
  baseUrlListPaid: 'formspayment/list/whitoutpermissions',
  baseUrlListProduct: 'productsandservices/list/whitoutpermissions',
  baseUrlListRol: 'rolesandpermissions/list/whitoutpermissions',
  baseUrlListSales: 'sales/list/whitoutpermissions',
  baseUrlListState: 'state/list/whitoutpermissions',
  baseUrlListTypeDocument: 'list/type-identifications',
  baseUrlListUser: 'user/list/whitoutpermissions',

  baseUrlRolCreate: 'rolesandpermissions/create',
  baseUrlRolDelete: 'rolesandpermissions/delete',
  baseUrlRolList: 'rolesandpermissions/list',
  baseUrlRolModules: 'rolesandpermissions/modules',
  baseUrlRolRead: 'rolesandpermissions/read',
  baseUrlRolUpdate: 'rolesandpermissions/update',
  baseUrlRolUpdatePermission: 'rolesandpermissions/update/permission',

  baseUrlCountryCreate: 'country/create',
  baseUrlCountryDelete: 'country/delete',
  baseUrlCountryList: 'country/list',
  baseUrlCountryRead: 'country/read',
  baseUrlCountryUpdate: 'country/update',

  baseUrlStateCreate: 'state/create',
  baseUrlStateDelete: 'state/delete',
  baseUrlStateList: 'state/list',
  baseUrlStateRead: 'state/read',
  baseUrlStateUpdate: 'state/update',

  baseUrlCityCreate: 'city/create',
  baseUrlCityDelete: 'city/delete',
  baseUrlCityList: 'city/list',
  baseUrlCityRead: 'city/read',
  baseUrlCityUpdate: 'city/update',

  baseUrlFormPaymentCreate: 'formspayment/create',
  baseUrlFormPaymentDelete: 'formspayment/delete',
  baseUrlFormPaymentList: 'formspayment/list',
  baseUrlFormPaymentRead: 'formspayment/read',
  baseUrlFormPaymentUpdate: 'formspayment/update',
  baseUrlGenerateCheckoutWompi: 'formspayment/wompi/generate-checkout',
  baseUrlgetSalePaymentWompi: 'formspayment/search-payment',

  baseUrlDeliveryTypeCreate: 'deliverytypes/create',
  baseUrlDeliveryTypeDelete: 'deliverytypes/delete',
  baseUrlDeliveryTypeList: 'deliverytypes/list',
  baseUrlDeliveryTypeRead: 'deliverytypes/read',
  baseUrlDeliveryTypeUpdate: 'deliverytypes/update',

  baseUrlInstitutionCreate: 'institutions/create',
  baseUrlInstitutionDelete: 'institutions/delete',
  baseUrlInstitutionList: 'institutions/list',
  baseUrlInstitutionRead: 'institutions/read',
  baseUrlInstitutionUpdate: 'institutions/update',

  baseUrlProductServiceCreate: 'productsandservices/create',
  baseUrlProductServiceDelete: 'productsandservices/delete',
  baseUrlProductServiceList: 'productsandservices/list',
  baseUrlProductServiceRead: 'productsandservices/read',
  baseUrlProductServiceUpdate: 'productsandservices/update',

  baseUrlClientCreate: 'clients/create/individual',
  baseUrlClientDelete: 'clients/delete',
  baseUrlClientList: 'clients/list',
  baseUrlClientRead: 'clients/read',
  baseUrlClientRegister: 'clients/register',
  baseUrlClientUpdate: 'clients/update',
  baseUrlClientUpload: 'clients/create',
  baseUrlClientProfile: 'clients/profile',
  baseUrlClientLogin: 'clients/login',
  baseUrlClientNewPassword: 'clients/password-reset',
  baseUrlClientRecoverPassword: 'clients/restore-password',

  baseUrlSaleCreate: 'sales/create',
  baseUrlSaleDelete: 'sales/delete',
  baseUrlSaleEmail: 'sales/read/email',
  baseUrlSaleList: 'sales/list',
  baseUrlSaleListPayment: 'sales/list/payments',
  baseUrlSaleRead: 'sales/read',
  baseUrlSaleUpdate: 'sales/update',
  baseUrlSaleUpdateImage: 'sales/update/image',

  baseUrlSaleProductCreate: 'sales/create/product',
  baseUrlSaleProductCancel: 'sales/update/cancel',
  baseUrlSaleProductUpdate: 'sales/update/product',
  baseUrlSaleProductNotify: 'sales/create/notify',
  baseUrlSaleDeliveryState: 'sales/update/deliverystate',
  baseUrlSaleSellingProduct: 'sales/list/selling',

  baseUrlSalePaidCreate: 'sales/create/payment',
  baseUrlSalePaidUpdate: 'sales/update/payment',
  baseUrlSalePaidApproved: 'sales/update/approved',
  baseUrlSalePaidCancel: 'sales/update/cancelpayment',

  baseUrlRecognitionCreate: 'recognition/create',
  baseUrlRecognitionList: 'recognition/list',
  baseUrlRecognitionAddImg: 'sales/update/addimages',

  baseUrlInventoryCreate: 'inventory/noncompositeproduct/create',
  baseUrlInventoryDelete: 'inventory/noncompositeproduct/delete',
  baseUrlInventoryList: 'inventory/noncompositeproduct/list',
  baseUrlInventoryRead: 'inventory/noncompositeproduct/read',
  baseUrlInventoryUpdate: 'inventory/noncompositeproduct/update',
  baseUrlInventoryActions: 'inventory/noncompositeproduct/actions',

  baseUrlInventoryCreateUsage: 'inventory/inventoryusage/create',
  baseUrlInventoryListUsage: 'inventory/inventoryusage/list',
  baseUrlInventoryUpdateUsage: 'inventory/inventoryusage/update',
  baseUrlInventoryDeleteUsage: 'inventory/inventoryusage/delete',
};
