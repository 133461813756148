import { inject, Injectable } from "@angular/core";
import { InventoryAbstraction } from "../../../ports/abstractions";
import { environment } from "@environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { RespInterface, RespListInterface } from "@common/ports/interfaces";
import { InventoryInterface } from "../../../ports/interfaces";
import { InventoryConsumptionInterface } from "@products-services/ports/interfaces";

@Injectable({
    providedIn: 'root'
})
export class InventoryRepository extends InventoryAbstraction {
    private readonly _baseUrlInventoryCreate: string = environment.baseUrlInventoryCreate;
    private readonly _baseUrlInventoryDelete: string = environment.baseUrlInventoryDelete;
    private readonly _baseUrlInventoryList: string = environment.baseUrlInventoryList;
    private readonly _baseUrlInventoryRead: string = environment.baseUrlInventoryRead;
    private readonly _baseUrlInventoryUpdate: string = environment.baseUrlInventoryUpdate;
    private readonly _baseUrlInventoryActions: string = environment.baseUrlInventoryActions;

    private readonly _baseUrlInventoryCreateUsage: string = environment.baseUrlInventoryCreateUsage;
    private readonly _baseUrlInventoryListUsage: string = environment.baseUrlInventoryListUsage;
    private readonly _baseUrlInventoryUpdateUsage: string = environment.baseUrlInventoryUpdateUsage;
    private readonly _baseUrlInventoryDeleteUsage: string = environment.baseUrlInventoryDeleteUsage;

    private http = inject(HttpClient);

    /** Non Composite Product */
    override list(query: string): Observable<RespListInterface<InventoryInterface>> {
        const url: string = this._baseUrlInventoryList;
        return this.http.get<RespListInterface<InventoryInterface>>(`${url}/${query}`);
    }

    override create(inventory: InventoryInterface): Observable<RespInterface<InventoryInterface>> {
        const url: string = this._baseUrlInventoryCreate;
        const { id, ...newInventory } = inventory;
        return this.http.post<RespInterface<InventoryInterface>>(`${url}`, newInventory);
    }

    override update(inventory: InventoryInterface): Observable<RespInterface<InventoryInterface>> {
        const url: string = this._baseUrlInventoryUpdate;
        return this.http.patch<RespInterface<InventoryInterface>>(`${url}/${inventory.id}`, inventory);
    }

    override read(id: string): Observable<RespInterface<InventoryInterface>> {
        const url: string = this._baseUrlInventoryRead;
        return this.http.get<RespInterface<InventoryInterface>>(`${url}/${id}`);
    }

    override delete(id: string): Observable<RespInterface<InventoryInterface>> {
        const url: string = this._baseUrlInventoryDelete;
        return this.http.delete<RespInterface<InventoryInterface>>(`${url}/${id}`);
    }

    override actions(inventory: any): Observable<RespInterface<string>> {
        const url: string = this._baseUrlInventoryActions;
        return this.http.patch<RespInterface<string>>(`${url}/${inventory.id}`, inventory);
    }

    /**Inventory Usage */
    override listInventoryUsage(query: string, id: string): Observable<RespListInterface<InventoryConsumptionInterface>> {
        const url: string = this._baseUrlInventoryListUsage;
        return this.http.get<RespListInterface<InventoryConsumptionInterface>>(`${url}/${id}${query}`);
    }

    override createInventoryUsage(inventory: InventoryConsumptionInterface): Observable<RespInterface<InventoryConsumptionInterface>> {
        const url: string = this._baseUrlInventoryCreateUsage;
        const { id, quantityEdit, nonCompositeProduct, ...newInventoryUsage } = inventory;
        newInventoryUsage.nonCompositeProductId = newInventoryUsage.nonCompositeProductId?.id;

        return this.http.post<RespInterface<InventoryConsumptionInterface>>(`${url}`, newInventoryUsage);
    }

    override updateInventoryUsage(inventory: InventoryConsumptionInterface): Observable<RespInterface<InventoryConsumptionInterface>> {
        const url: string = this._baseUrlInventoryUpdateUsage;
        inventory.quantity = inventory.quantityEdit;
        const { quantityEdit, nonCompositeProduct, ...newInventoryUsage } = inventory;
        return this.http.patch<RespInterface<InventoryConsumptionInterface>>(`${url}/${inventory.id}`, newInventoryUsage);
    }

    override deleteInventoryUsage(id: string): Observable<RespInterface<InventoryConsumptionInterface>> {
        const url: string = this._baseUrlInventoryDeleteUsage;
        return this.http.delete<RespInterface<InventoryConsumptionInterface>>(`${url}/${id}`);
    }
}