import { ApplicationConfig, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { provideRouter, withViewTransitions } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';

import { routes } from './app.routes';

import { EvokeInterceptor } from '@common/domain/interceptor/evoke.interceptor';

import { AuthAbstraction } from '@auth/ports/abstractions';
import { ListAbstraction, LoadingAbstraction, ToastAbstraction } from '@common/ports/abstractions';
import { RolPermissionAbstraction } from '@roles-permissions/ports/abstractions';
import { UserAbstraction } from '@users/ports/abstractions';
import { CountryAbstraction } from '@countries/ports/abstractions';
import { StateAbstraction } from '@state/ports/abstractions';
import { CityAbstraction } from '@city/ports/abstractions';
import { FormPaymentAbstraction } from '@form-payment/ports/abstractions';
import { DeliveryTypeAbstraction } from '@delivery-type/ports/abstractions';
import { InstitutionAbstraction } from '@institution/ports/abstractions';
import { ProductServiceAbstraction } from '@products-services/ports/abstractions';
import { ClientAbstraction } from '@clients/ports/abstractions';
import { SaleAbstraction } from '@sales/ports/abstractions';
import { RecognitionAbstraction } from '@recognition/ports/abstrations';

import { AuthRepository } from '@auth/infraestructure/gateways/http';
import { RolPermissionRepository } from '@roles-permissions/infraestructure/gateways/http';
import { ToastRepository, LoadingRepository, ListRepository } from '@common/infraestructure/gateways/http';
import { UserRepository } from '@users/infraestructure/gateways/http';
import { CountryRepository } from '@countries/infraestructure/gateways/http';
import { StateRepository } from '@state/infraestructure/gateways/http';
import { CityRepository } from '@city/infraestructure/gateways/http';
import { FormPaymentRepository } from '@form-payment/infraestructure/gateways/http';
import { DeliveryTypeRepository } from '@delivery-type/infraestructure/gateways/http';
import { InstitutionRepository } from '@institution/infraestructure/gateways/http';
import { ProductServiceRepository } from '@products-services/infraestructure/gateways/http';
import { ClientRepository } from '@clients/infraestructure/gateways/http';
import { SaleRepository } from '@sales/infraestructure/gateways/http';
import { RecognitionRepository } from '@recognition/infraestructure/gateways/http';

import localeEsCO from "@angular/common/locales/es-CO";
import { InventoryAbstraction } from './inventory/ports/abstractions';
import { InventoryRepository } from './inventory/infraestructure/gateways/http';

registerLocaleData(localeEsCO);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
    ),
    provideHttpClient(
      withInterceptors([
        EvokeInterceptor
      ])
    ),
    importProvidersFrom(
      HttpClientModule, BrowserAnimationsModule
    ),
    { provide: LOCALE_ID, useValue: 'es-CO' },
    { provide: AuthAbstraction, useClass: AuthRepository },
    { provide: ListAbstraction, useClass: ListRepository },
    { provide: LoadingAbstraction, useClass: LoadingRepository },
    { provide: RolPermissionAbstraction, useClass: RolPermissionRepository },
    { provide: ToastAbstraction, useClass: ToastRepository },
    { provide: UserAbstraction, useClass: UserRepository },
    { provide: CountryAbstraction, useClass: CountryRepository },
    { provide: StateAbstraction, useClass: StateRepository },
    { provide: CityAbstraction, useClass: CityRepository },
    { provide: FormPaymentAbstraction, useClass: FormPaymentRepository },
    { provide: DeliveryTypeAbstraction, useClass: DeliveryTypeRepository },
    { provide: InstitutionAbstraction, useClass: InstitutionRepository },
    { provide: ProductServiceAbstraction, useClass: ProductServiceRepository },
    { provide: ClientAbstraction, useClass: ClientRepository },
    { provide: SaleAbstraction, useClass: SaleRepository },
    { provide: RecognitionAbstraction, useClass: RecognitionRepository },
    { provide: InventoryAbstraction, useClass: InventoryRepository },
  ]
};
